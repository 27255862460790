<template>
    <vx-card title="Currency Exchange Rate">
        <vs-tabs>
            <vs-tab label="Tax Rate">
                <tax-rate></tax-rate>
            </vs-tab>
            <vs-tab label="Daily Rate">
                <bi-rate></bi-rate>
            </vs-tab>
        </vs-tabs>
    </vx-card>
</template>
<script>
import BiRate from "./bi-rate"
import TaxRate from "./tax-rate"
export default {
    components: {
        BiRate,
        TaxRate,
    }
}
</script>